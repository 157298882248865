




import {Component, Vue} from 'vue-property-decorator';
import {LogoutMutation} from "@/graphql/auth";
import PusherService from "@/PusherService";

@Component
export default class Logout extends Vue {
  mounted() {
    this.logout();
  }

  logout() {
    this.$apollo
      .mutate({mutation: LogoutMutation})
      .then((_response) => {
        if(this.$store.state.active_board_pid) {
          PusherService.unsubscribeBoardSubscription(this.$store.state.active_board_pid);
        }
        PusherService.unsubscribeWorkspaceSubscription(this.$store.state.active_workspace_pid);
        this.$store.commit('logged_out')
        this.$router.push({name: 'login'}).then(() => {
          location.reload();
        })
      })
      .catch((_error) => {
        
      })
  }
}
